@font-face {
    font-family: 'Inter';
    src:
        url('./fonts/Inter-Regular/Inter-Regular.ttf') format('truetype'),
        url('./fonts/Inter-Regular/Inter-Regular.woff') format('woff'),
        url('./fonts/Inter-Regular/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
}
@font-face {
    font-family: 'Inter';
    src:
        url('./fonts/Inter-Medium/Inter-Medium.ttf') format('truetype'),
        url('./fonts/Inter-Medium/Inter-Medium.woff') format('woff'),
        url('./fonts/Inter-Medium/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
}
@font-face {
    font-family: 'Inter';
    src:
        url('./fonts/Inter-SemiBold/Inter-SemiBold.ttf') format('truetype'),
        url('./fonts/Inter-SemiBold/Inter-SemiBold.woff') format('woff'),
        url('./fonts/Inter-SemiBold/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
}
@font-face {
    font-family: 'Inter';
    src:
        url('./fonts/Inter-Bold/Inter-Bold.ttf') format('truetype'),
        url('./fonts/Inter-Bold/Inter-Bold.woff') format('woff'),
        url('./fonts/Inter-Bold/Inter-Bold.woff2') format('woff2');
    font-weight: 700;
}
